import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from './shared/services/session-service/session.service';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'salik-call-center-ui';

  constructor(private router: Router, private userIdle: UserIdleService,
    private sessionStorage: SessionService) {
    console.log(`Health check times--->${environment.deploymentTime}`)
  }
  ngOnInit(): void {
    // let  workflow = 'quote_details';
    // this.router.navigateByUrl(`/${workflow}`)
    //Start watching for user inactivity.
    // this.checkSession();
    // this.userIdle.startWatching();

    // // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe((count) => {
    // });

    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => {
    //   sessionStorage.clear();
    //   this.userIdle.stopWatching();
    //   this.router.navigateByUrl('');
    // });
  }

  // @HostListener('document:keypress')
  // @HostListener('document:click')
  // @HostListener('document:wheel')
  // resetTimer() {
  //   this.userIdle.resetTimer();
  // }

}