// auth.guard.ts
import { Token } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import {ROLE, TOKEN} from 'src/app/shared/session-variables';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private sessionStorage: SessionService
  ) {}
  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.sessionStorage.get(TOKEN);
    const role = this.sessionStorage.get(ROLE);
    if (token) {
      const allowedRoles = route.data['roles'] as Array<string>;

      if (allowedRoles && allowedRoles.includes(role)) {
        return true;
      } else if (!allowedRoles) {
        return true;
      }
    }

    this.router.navigate(['/']);
    return false;
  }
}
