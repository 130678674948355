import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/authentication/guards/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { roles: ['agent'] }
  },
  {
    path: 'callcenteradmin',
    loadChildren: () => import('./modules/call-center-admin/call-center-admin.module').then(m => m.CallCenterAdminModule),
    canActivate: [AuthGuard],
    data: { roles: ['callcenteradmin'] }
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] }
  },
  {
    path: 'error-history',
    loadChildren: () => import('./modules/error-history/error-history.module').then(m => m.ErrorHistoryModule),
    canActivate: [AuthGuard],
    data: { roles: ['superadmin'] }
  },
  {
    path: '',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: "call-center-admin",
    loadChildren: () => import('./modules/call-center-admin/call-center-admin.module').then(m => m.CallCenterAdminModule),
    canActivate: [AuthGuard] ,
    data: { roles: ['callcenteradmin'] } 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
