import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionTimeout: number = 60 * 60 * 1000; // 1 hour in milliseconds

  constructor(private router: Router) { }
  
  set(key:string,value:string){
    sessionStorage.setItem(key, value);
  }

  get(key:string):any{
    return sessionStorage.getItem(key);
  }

  remove(key:string):any{
    return sessionStorage.removeItem(key);
  }
  // Check if session is expired
  checkSession() {
    const loginTime = sessionStorage.getItem('loginTime');
    console.log("loginTime", loginTime);
        setTimeout(() => this.logout(), 3600000); // as of now i set it for 2 min we can modified time here 
}

// Logout the user by clearing the session and redirecting
logout() {
    sessionStorage.removeItem('loginTime'); 
    sessionStorage.clear(); // Optionally, clear other session-related data if needed
    this.router.navigateByUrl(''); // Redirect to login page
}
}
