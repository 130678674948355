export const TOKEN = "token";
export const TPA_ID = "tpaId";
export const EMIRATE_ID = "emirateId";
export const GROUP_ID = "groupId";
export const PLAN_ID = "planId";
export const ROLE = "role";
export const USERID = "userId";
export const CUSTOMERUUVID = "customerUuvid";
export const CUSTOMERID = "customerId"
export const FailedQuoteDate = "failedQuoteDate"
export const GenerateQuoteDate = "generateQuoteDate"
export const ERRORHISTORYPAGE =  "errorHistoryPage";
export const AURASALIKID ="auraSalikId"

